<template>
  <div class="home">
    <div class="list" v-show="list.length">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        @click="claimDetail(item.claims.claimsId)"
      >
        <img class="bg" src="../assets/img/listBG.png" alt="" />
        <div class="num">
          <p>{{ item.policyPlan.planName }}保障方案</p>
          <p>查看详情<van-icon name="arrow" /></p>
        </div>
        <div class="date">
          <p>理赔单号</p>
          <p>{{ item.claims.claimsId }}</p>
        </div>
        <div class="date">
          <p>申请日期</p>
          <p>{{ item.claims.applyTime }}</p>
        </div>
        <div class="schedule">
          <p>理赔进度</p>
          <p>{{ item.policy.securityStatus }}</p>
        </div>
      </div>
    </div>
    <div class="nay" v-show="!list.length">
      <img src="../assets/img/nay.png" alt="" />
      <p>当前没有理赔</p>
    </div>
  </div>
</template>

<script>
import { claimsList } from "../utils/api";
export default {
  data() {
    return {
      list: null,
    };
  },
  created() {},
  methods: {
    //理赔列表
    async claimsList() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await claimsList().then((res) => {
        this.list = res.data;
      });
    },
    //理赔进度
    claimDetail(claimsId) {
      this.$router.push({
        path: "/claimDetail",
        query: {
          claimsId: claimsId,
        },
      });
    },
  },
  async mounted() {
    await this.claimsList();
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 12px;
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .item {
      width: 100%;
      border-radius: 4px;
      // background: #ffffff;
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      margin-top: 20px;
      padding: 15px 15px;
      position: relative;
      .bg {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -100;
        width: 103px;
      }
      .num {
        margin-top: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-size: 12px;
          font-weight: bold;
          color: #333333;
          line-height: 17px;
        }
        p:last-child {
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          line-height: 17px;
          display: flex;
          align-items: center;
        }
      }
      div {
        display: flex;
        align-items: center;
        margin-top: 18px;
        p {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 17px;
        }
        p:first-child {
          margin-right: 10px;
        }
      }
      // .date {
      //   margin: 15px 0 15px 0;
      // }
      div:first-child {
        margin-top: 0;
      }
    }
    .item:first-child {
      margin-top: 0;
    }
  }
  .nay {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 119px;
    p {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      line-height: 17px;
    }
  }
}
</style>